<template>
  <v-row>
    <v-col
      v-for="(item, index) in items"
      :key="index"
      v-if="item.model"
      cols="12"
      :sm="items.length === 2 ? 6 : 3"
      :lg="items.length === 3 ? 4 : (items.length === 2 ? 6 : 3)"
    >
      <stat-box
        :title="item.title()"
        :class="{ [`overview-card overview-card--${item.color}`]: true }"
        :text="item.text"
        rounded
        shadow
      >
        <template v-slot:tooltip>
          <v-tooltip
            v-if="item.tooltip"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="isThemeLight ? 'black' : null"
                v-bind="attrs"
                v-on="on"
              >
                help_outline
              </v-icon>
            </template>
            <span>
              {{ item.tooltip }}
            </span>
          </v-tooltip>
        </template>
      </stat-box>
    </v-col>
  </v-row>
</template>

<script>
// Import the child component
const StatBox = () => import(/* webpackChunkName: "blocks-stat-box" */ "@/blocks/user-profile/common/StatBox.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Credibility",

  // Accept incoming data from parent
  props: {
    audienceData: {
      type: Object,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    },

    platform: {
      type: String,
      required: true
    }
  },

  // Register the component
  components: {
    StatBox
  },

  // Options API computable properties
  computed: {
    /**
     * Get all the tab items to be shown
     *
     * @returns {Array}
     */
    items() {
      const possibleData = {
        followers: [
          {
            color: "green",
            model: this.audienceData.extraData.followersCreds,
            title: () => this.audienceData.extraData.followersCreds.toFixed(2) + '%',
            text: "Followers' Credibility",
            tooltip: "To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above."
          },
          {
            color: "red",
            model: this.audienceData.extraData.notableFollowers,
            title: () => this.audienceData.extraData.notableFollowers.toFixed(2) + '%',
            text: "Notable Followers",
            tooltip: "Shows what percentage of followers are influencers. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%."
          }
        ],

        likes: [
          {
            color: "green",
            model: this.audienceData.extraData.likerCreds,
            title: () => this.audienceData.extraData.likerCreds.toFixed(2) + '%',
            text: "Engagers' Credibility",
            tooltip: `To establish the audience credibility score for active audience we take into account factors such as an account's avatar and bio description, number of posts, number of accounts followed vs following ratio. Please note that the audience credibility is based only on active engaged audience (likes) i.e. a high credibility score does not necessarily indicate "real followers" - the account may still have bought fake followers, the engagement rate could be very low but those few engagements are real and therefore the credibility score could still be high. Influencers with genuine audience will attain scores of 80 or above.`
          },
          {
            color: "red",
            model: this.audienceData.extraData.notableLikes,
            title: () => this.audienceData.extraData.notableLikes.toFixed(2) + '%',
            text: "Notable Engagers",
            tooltip: `Shows what percentage of engagers are influencers. Notable engagers for a 0.99 quantile can largely differ from 1% to 20%.`
          },
          {
            color: "orange",
            model: this.audienceData.extraData.nonFollowerLikes,
            title: () => this.audienceData.extraData.nonFollowerLikes.toFixed(2) + '%',
            text: "Engagements not from Followers"
          }
        ]
      }

      // Based on the selected audience tab (followers/comments/likes), show the items
      return possibleData[this.selectedTab]
    }
  },
}
</script>

<style lang="stylus">
.overview-card
  &--green
    color rgb(56, 142, 60) !important
  &--red
    color rgb(186, 72, 66) !important
  &--orange
    color rgb(240, 93, 86) !important
</style>
